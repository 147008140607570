// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

$primary: #09cd57;
$secondary: #7646de;

$custom-colors: (
  "primary": $primary,
  "secondary": $secondary
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);
$enable-negative-margins: true;

// 5. Include remainder of required parts
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/list-group";

$all-colors: map-merge-multiple($blues, $grays, $theme-colors);

$utilities: map-merge(
  $utilities,
  (
    "color": map-merge(
      map-get($utilities, "color"),
      (
        values: map-merge(
          map-get(map-get($utilities, "color"), "values"),
          (
            $all-colors
          ),
        ),
      ),
    ),
  )
);

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here



body, html{
    height: 100%;
}

.vh-50{
    height: 50vh;
}


:root{
    --bs-font-sans-serif: 'Inter', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
}

.navbar{
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, .786);

}

.emoji-send{
    cursor: pointer;
    
}

.random-chat-icons{
    opacity: .18;
}

.large-cta{
    font-size: calc( max( 9vw, 40px ) );
    line-height: calc( max( 9vw, 40px ) );

    @include media-breakpoint-up(md) {
        font-size: calc( max( 12vw, 80px ) );
        line-height: calc( max( 12vw, 80px ) );
    }
}

.gradient-bg{
    background: rgb(9,205,87);
    background: linear-gradient(59deg, rgba(9,205,87,0.49205619747899154) 0%, rgba(9,205,87,0.06908700980392157) 100%);
}

.email-info{
    text-overflow: ellipsis;
    overflow: hidden;
}

.text-small{
    font-size: .7rem;
    color: $gray-500
}